import React from "react"

import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import Breadcrumbs from "../../components/Breadcrumbs"

import "./Pages.css"

const QuemSomos = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Quem Somos"
        canonical={`${process.env.GATSBY_HOST_URL}/pt/quem-somos`}
        isAlternate={true}
        alternateUrl={`${process.env.GATSBY_HOST_URL}/en/about-us`}
      />

      <div className="topics">
        <div className="main-content" style={{ marginTop: 117 }}>
          <Breadcrumbs
            currentPage="Quem Somos"
            style={{ marginTop: 0, marginBottom: 30 }}
          />

          <div className="topics-header">
            <h1>Quem Somos</h1>
          </div>

          <div className="content-static-pages">
            <p>
              Apaixonados pelo rico universo islâmico e inconformados com o quão
              pouco as pessoas conhecem dele, decidimos reunir em um só lugar
              variados assuntos que possam enriquecer a discussão do “o que é
              Islam” e “quem são os muçulmanos”, tendo a cultura e conhecimento
              como os principais pilares. E claro, todos conteúdos possuem suas
              fontes checadas, avaliadas humanamente por nossa equipe.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default QuemSomos
